<template>
  <div
    class="result-container"
    :style="{
      backgroundImage: 'url(' + img1 + ')',
    }"
  >
    <div class="result-main">
      <p class="p-t44 rem46 lh31 sorry">很抱歉！</p>
      <p class="rem30 lh31 c-999999">您的综合评分不足，暂未审批通过</p>
      <div class="result-body">
        <div class="rem30 lh21 mb7 f500">温馨提示</div>
        <div class="rem30 lh28 c-303133">
          <div class="flex"><span class="icon-qq"></span> 请保持良好的信用行为</div>
        </div>
      </div>
      <div class="result-btn m5">
        <van-button round block plain color="#4D7BFE" class="btn" @click="toIndex">
          <span class="rem34">返回首页</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Button } from 'vant'
Vue.use(Button)
export default {
  data() {
    return {
      img1: require('@/assets/images/borrow/refuse-result.png'),
    }
  },
  methods: {
    toIndex() {
      this.$router.push({ path: this.$store.state.common.defaultIndex })
    },
  },
}
</script>

<style lang="less" scoped>
.result-container {
  padding: 0 0.42rem /* 21/50 */;
  background-size: 100% 8.4rem /* 420/50 */;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  .result-body {
    background-color: #fff;
    margin-top: 0.72rem /* 36/50 */;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 0.38rem /* 19/50 */ 0.3rem 0.96rem /* 15/50 */;
    border-radius: 4px;
    .icon-qq {
      margin-top: 0.18rem /* 9/50 */;
      display: inline-block;
      width: 0.2rem /* 10/50 */;
      height: 0.2rem /* 10/50 */;
      border-radius: 50%;
      border: 0.04rem /* 2/50 */ solid #4d7bfe;
      box-sizing: border-box;
      margin-right: 0.1rem /* 5/50 */;
    }
  }

  .result-btn {
    margin-top: 0.82rem /* 41/50 */;
  }
  .btn {
    margin-top: 0.48rem /* 24/50 */;
  }
  .sorry {
    color: #fe4d4d;
  }
}
</style>
